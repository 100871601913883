body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #0D0F11;
}

.App {
  /* overflow: hidden; */
}

/* img {
  width: 100%;
  height: auto;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* mobile screens */
@media screen and (max-width: 720px) {
  body {
      background-color: #000000;
  }
}

.inner-page {
    min-width: 1920px;
}

.hero {
    position: relative;
    overflow: hidden;
    background-color: #0D0F11;
    width: 100vw;
    height: 100vh;
}

.logo-container {
    display: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    position: relative;
}

#logo-red-heart {
    position: absolute;
    top: 6px;
    right: 25.54px;
}

.hero-text {
    width: 100%;
    position: absolute;
    left: 11.7%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 999;
}

.italic-regular {
    font-family: 'Fraunces', serif;
    font-style: italic;
}

.progress {
    font-size: 68px;
    line-height: 93px;
    font-weight: 500;
    font-style: italic;
    margin: 0 35px;
    width: 4rem;
}

.experience-text {
    font-weight: 600;
    font-size: 100px;
    line-height: 121px;
    margin-top: 0;
}

.now-text {
    font: italic 100px 'Fraunces', serif;
    font-weight: 400;
    line-height: 121px;
}

#now {
    /* font-style: italic;
    font-weight: 700;
    line-height: 137px; */
    position: relative;
}

#now-red-heart {
    position: absolute;
    top: -78.54px;
    right: -61.54px;
}

#now-red-heart img {
    width: 77px;
    height: 77px;
}

.wait-text {
    position: absolute;
    font-size: 20px;
    line-height: 24px;
    margin-top: 5rem;
}

.gradient-bg {
    height: 100vh;
    position: absolute;
    top: 100%;
    display: none;
}

#mobile_cactus2 {
    translate: 5% 0%;
}

#bella_logo {
    /* display: none; */
}

#page_load,
#page_load_mobile {
    display: none;
}

#page_load_mobile #wink {
    display: none;
    -webkit-transform: translateX(2.75%);
            transform: translateX(2.75%);
}

#bella {
    display: none;
    /* transform: translateX(-10%); */
}

#oncoming_car {
    /* display: none; */
    translate: 30% 30%;
}

#socials_right {
    display: none;
}

#snapchat_link path,
#facebook_link path,
#tiktok_link path,
#instagram_link path,
#twitter_link path,
#free_trial_link path {
  transition: fill 0.5s ease-out;
}

#snapchat_link:hover path,
#free_trial_link:hover .text {
    fill: #FCEA2B;
}

#free_trial_link:hover .gift {
    fill: #DD2335;
}

#facebook_link:hover .text,
#facebook_link:hover .f-vector {
    fill: #FFFFFF;
}

#facebook_link:hover .circle-vector {
    fill: #1877F2;
}

#tiktok_link:hover .text,
#tiktok_link:hover .vector_3 {
    fill: #00F2EA;
}

#tiktok_link:hover .vector_2 {
    fill: #000000;
}

#tiktok_link:hover .vector_1 {
    fill: #FF004F;
}

#instagram_link:hover .text,
#instagram_link:hover .vector_3 {
    fill: #FFFFFF;
}

#instagram_link:hover .vector_1,
#instagram_link:hover .vector_2 {
    display: none;
}

#instagram_link .vector_1_hovered,
#instagram_link .vector_2_hovered {
    display: none;
}

#instagram_link:hover .vector_1_hovered,
#instagram_link:hover .vector_2_hovered {
    display: block;
}

#instagram_link:hover .vector_1 {
    fill: "url(#paint0_radial_62_139)";
}

#instagram_link_mobile #instagram_logo_hovered {
    display: none;
}

#instagram_link_mobile:hover .text {
    fill: #FFFFFF;
}

#instagram_link_mobile:hover #instagram_logo {
    display: none;
}

#instagram_link_mobile:hover #instagram_logo_hovered {
    display: block;
}

#twitter_link:hover .text {
    fill: #FFFFFF;
}

#twitter_link .twitter_stroke {
    display: none;
}

#twitter_link:hover .twitter_stroke {
    display: block;
}

#twitter_link:hover .twitter_logo {
    fill: #55ACEE;
}

#socials_left {
    display: none;
}

/* Reusable classnames */
.white-text {
    color: #ffffff;
}

.transparent-text {
    color: transparent;
}

#wink {
    display: none;
}

.line-1, .line-2, .line-3 {
    display: inline;
    opacity: 0;
    position: absolute;
}

.line-1 {
    -webkit-transform: translate(25px, 0px);
            transform: translate(25px, 0px);
}

/* mobile screens */
@media screen and (max-width: 720px) {
    .hero {
        /* padding: 4rem 0; */
        background-color: #000000;
    }

    .logo-container {
        display: block;
        margin-top: 4rem;
    }

    /* .hero-text{
        margin: 0 21px 0 16px;
    } */

    .hero-text {
        width: 100%;
        position: absolute;
        left: 1rem;
        top: 35%;
        z-index: 999;
    }

    .line-1 {
        -webkit-transform: translate(0);
                transform: translate(0);
    }

    .progress {
        display: block;
        margin: 0 0 3px 0;
        font-size: 19.9px;
        line-height: 27px;
    }

    .experience-text {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        position: relative;
    }

    .now-text {
        font: italic 30px 'Fraunces', serif;
        font-weight: 400;
        line-height: 37px;
    }

    #now {
        line-height: 41px;
    }

    .wait-text {
        font-size: 12px;
        line-height: 15px;
        margin-top: 2.5rem;
    }
    #now-red-heart {
        right: -13px;
        top: -30px;
    }
    #now-red-heart img {
        width: 28.46px;
        height: 28.46px;
    }
}

@supports (-webkit-text-stroke: 1px black) {
    .now-text {
      -webkit-text-stroke: 1px #ffffff;
      /* -webkit-text-fill-color: white; */
    }
}
